<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="flex flex-wrap p-fluid mt-4">
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.policyBodySectionTemplate.label' | translate: 'Policy Body Section Template'"
        [placeholder]="
          moduleFieldString + '.policyBodySectionTemplate.placeholder' | translate: 'Enter Policy Body Section Template'
        "
        [control]="policyBodySectionTemplateControl"
        [viewMode]="'create'"
        [multi]="false"
        [tableMode]="true"
        [singleTableMode]="true"
        [inlineMode]="true"
        [optionLabel]="'subject'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [customProjectionFields]="['code', 'subject', 'type', 'body']"
        [targetTypes]="['POLICY_BODY_SECTION_TEMPLATE']"
        [appendTo]="'body'"
      >
      </app-target-code-selector>
    </div>
  </div>

  <div class="flex flex-row-reverse gap-2">
    <!-- <app-button *ngIf="fieldViewMode != 'view'" [action]="submitButtonAction"></app-button> -->
    @if (showSaveButton) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }

    <!-- <app-button *ngIf="fieldViewMode != 'view'" [action]="detailsButtonAction"></app-button> -->
    <!-- <app-button *ngIf="fieldViewMode != 'view' && showCancel" [action]="cancelButtonAction"></app-button> -->
  </div>
</form>
